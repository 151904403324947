// Customizable Area Start
import React from "react";

import {
  Props,
} from "./TermsConditionsWrapperController.web";
import TermsConditionsWrapperController from "./TermsConditionsWrapperController.web";
import LoginLayout from "../../../components/src/LoginLayout";
import { withRouter } from "react-router-dom";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { Box, styled,Typography } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web"

export class TermsConditionsWrapper extends TermsConditionsWrapperController {
  constructor(props: Props) {
    super(props);
  }
  render() {

    return (
      this.state.userAuth ? (
        <ProjectSideBar pageThisVar={this}>
             {      
      this.state.termsConds &&
      <Box>
      <StyledContainer 
      data-test-id="descriptionTestId"
        dangerouslySetInnerHTML={{
          __html: `${this.state.termsConds}`
        }} />
        </Box>}
        { !this.state.termsConds && <Loader loading={true} />}
        </ProjectSideBar>
      ):
      <TermsConditionsLayout>
    <LoginLayout>
      {      
      this.state.termsConds &&
      <Box style={{width:"100%",height:"100%"}}>
        <Box style={{width:"100%",margin:"auto",borderBottom: "1px solid #DBE7F0"}}>
        <Typography style={{marginBottom:"2%",fontSize: "24px",fontFamily: "Montserrat-regular"}}>Terms & Conditions</Typography>
        </Box>
      <StyledContainer 
      data-test-id="descriptionTestId"
        dangerouslySetInnerHTML={{
          __html: `${this.state.termsConds}`
        }} />
        </Box>}
        { !this.state.termsConds && <Loader loading={true} />}
    </LoginLayout>
 </TermsConditionsLayout>
    )
    

  }
}

const TermsConditionsLayout = styled(Box)({
 
  '& .boyImage': { 
    '& img': {
      display:"none",
   
    },
  },
  "& .contentContainer":{
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center',
    position:"initial",
    "@media (min-width:360px) and (max-width: 449px)": {
      marginTop:"0%"
    },
    "@media (min-width:450px) and (max-width:1280px)":{
      marginTop:"0%"
    },
  },
  "& .logoContainer": {
    height: "10%",
    '& img': {
      objectFit: "cover",
      height: "100%",
    },
    "@media  (max-width:1280px)": {
      display: "none"
    },
  },
  "& .ChilderContainer":{
    display: "flex",
    alignItems: "center",
    top: "220px",

    width:"100%",
    "@media (min-width:360px) and (max-width: 449px)": {
      position: "inherit",
    },
  "@media (min-width:450px) and (max-width:1280px)":{
    position: "inherit",
    },

  },
  "&. title":{
    borderBottom: "1px solid #DBE7F0",
    width:"95.50%",
    margin:"auto"
  }
})
const StyledContainer = styled(Box)({
  padding: "38px 48px",
  marginBottom:"100px",
  "@media (max-width: 900px)": {
    padding: "15px",
    marginBottom:"150px",
  },
  "& p":{
    marginBottom: "24px"
  },
  "& p:first-child strong":{
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "Montserrat-bold"
  },
  "& p sup strong":{
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Montserrat-bold"
  },
  "& p sub strong":{
    color: "#4D4D4D",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Montserrat-semibold"
  },
  "& p sub":{
    fontSize: "14px",
   color: "#666666",
   fontFamily: "Montserrat-regular",
   "@media (min-width:360px) and (max-width: 449px)": {
    fontSize: "13px",
  },
  },
  "& ul":{
    listStyle: "none",
    padding: "0",
    fontSize: "14px",
    color:"#4D4D4D",
    marginBottom: "24px"
  },
  "& ul li span":{
    color: "#118799 !important"
  }
});

const StyledBox = styled(Box)({
  marginTop: "40px"
})
export default withRouter(TermsConditionsWrapper);
// Customizable Area End
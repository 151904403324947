// Customizable Area Start
import React from "react";
import NewPasswordController, { } from "./NewPasswordController.web";
import { Box, Grid, InputLabel, Grow, InputAdornment, IconButton, Button } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ContainedButton } from '../../../components/src/GlobalButton'
import ProjectText from "../../../components/src/ProjectText";
import ProjectTextField from "../../../components/src/ProjectTextField";
import LoginLayout from "../../../components/src/LoginLayout";
import 'react-phone-input-2/lib/style.css'
import { Formik } from "formik";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import * as Yup from 'yup';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class NewPassword extends NewPasswordController {
  NewPasswordSchema = () => {
    return Yup.object().shape({
      newPassword: Yup.string().matches(/^\S+$/, "Please enter the valid password").min(8, "New Password Must Be At Least 8 Characters").matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*\d)(?=.*?[#?!@$%^&*-]).{8,}$/, "Must be 8 characters and contain at least 1 number ,1 uppercase , 1 lowercase and 1 special character").required("New Password Is Required"),
      ConfirmPassword: Yup.string().required("Confirm Password Is Required")
    })
  };
  render() {
    return (
      <NewPasswordLayout>
      <LoginLayout BackButton BackButtonOnClick={() => {
        this.props.history.goBack()
      }}>
        <Formik
        data-testid="Formik"
          validationSchema={() => this.NewPasswordSchema()}
          validateOnChange={true}
          initialValues={{
            newPassword: "",
            ConfirmPassword: ""
          }}
          validateOnBlur={true}
          onSubmit={(values) => {
            this.ChangePassword(values)
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off" className="newPasswordContainer">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ProjectText variant="h4"
                     className="LoginText txtTransf"  fontfamily="Montserrat-Bold" textcolor="#031417" gutterBottom>
                      Set new password
                  </ProjectText>
                </Grid>
                <Grid item xs={12} className="mb-16">
                  <InputLabel
                    htmlFor="SetNewPassword">
                    <ProjectText variant="subtitle1" fontweight="700" fontfamily="Montserrat-Bold" textcolor="#031417" className="inputName">
                      New password
                    </ProjectText>
                  </InputLabel>
                  <Box className="newPasswordInput">
                    <ProjectTextField
                    data-testid="password"
                      type={this.state.TextFiledType ? 'text' : 'password'}
                      fullWidth
                      error={(errors.newPassword !== undefined && touched.newPassword !== undefined) || this.state.ErrorPopUpType === "Error"}
                      id="SetNewPassword"
                      name="SetNewPassword"
                      value={values.newPassword}
                      inputProps={{ maxLength: 12 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => this.SetTextFiledType()}
                          >
                            {this.state.TextFiledType ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.SetNewPassword(e, setFieldValue)
                      }}
                    />
                    {(touched.newPassword && errors.newPassword) &&
                      <Grow
                        in={errors.newPassword !== undefined}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(!errors.newPassword ? { timeout: 800 } : {})}
                      >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.newPassword}</ProjectText>
                      </Grow>
                    }
                  </Box>
                </Grid>
                <Grid item xs={12} className="mb-16">
                  <InputLabel htmlFor="ConfirmPassword">
                    <ProjectText variant="subtitle1" fontweight="700" fontfamily="Montserrat-Bold" textcolor="#031417" className="inputName">
                      Confirm new password
                    </ProjectText>
                  </InputLabel>
                  <Box className="newPasswordInput">
                    <ProjectTextField
                    data-testid="newpassword"
                      type={this.state.TextFiledTypeForConfirm ? 'text' : 'password'}
                      fullWidth
                      inputProps={{ maxLength: 12 }}
                      error={(errors.ConfirmPassword !== undefined && touched.ConfirmPassword !== undefined) || this.state.ErrorPopUpType === "Error"}
                      id="ConfirmPasswordPassword"
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => {
                              this.SetTextFiledTypeForConfirm()
                            }}
                          >
                            {this.state.TextFiledTypeForConfirm ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.SetConfirmPassoword(e, setFieldValue)} />
                    {(touched.ConfirmPassword && errors.ConfirmPassword) &&
                      <Grow
                        in={errors.ConfirmPassword !== undefined}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(!errors.ConfirmPassword ? { timeout: 800 } : {})}
                      >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.ConfirmPassword}</ProjectText>
                      </Grow>
                    }
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="mt-10 saveButton">
                    <ContainedButton type="submit" bgcolor="success" fullWidth size="medium" data-testid="loginbutoon">
                      Save
                    </ContainedButton>
                  </Box>
                  {this.state.Sendotp && (
                    <Box className="ErrorBoxContainer">
                      {this.state.ErrorPopUpType === "Error" &&( <Button
                        variant="contained"
                        style={{
                          backgroundColor: "white",
                          color: "#DC2626",
                        }}
                      >
                        {this.state.PassWordMatchText}
                      </Button> )}
                       {  this.state.ErrorPopUpType ==="Success"&& 
                       (
                       <Button
                          variant="contained"
                          style={{
                            backgroundColor: "white",
                            color: "black",
                          }}
                          startIcon={
                            <CheckCircleIcon style={{ color: "#5DBB65" }} />
                          }
                        >
                          {this.state.PassWordMatchText}
                        </Button>
                       )
                      }
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </LoginLayout>
      </NewPasswordLayout>
    )
  }
}

export default withRouter(NewPassword)
const NewPasswordLayout = styled(Box)({
  "& .newPasswordContainer":{
    width:"26.05vw", 
    height:"33.71vh",
    "@media (min-width:360px) and (max-width: 449px)": {
      top:"none",
      left:"none",
      width:"75.40vw", 
      height:"33.71vh",
      position:"initial",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      top:"none",
      left:"none",
      width:"75.40vw", 
      height:"33.71vh",
      position:"initial", 
    },
  },
  "& .newPasswordInput":{
    position: "relative" ,width:"26.05vw",
    "@media (min-width:360px) and (max-width: 449px)": {
      width:"75.40vw", 
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width:"75.40vw", 
    },
  },
  "& .logoContainer": {
    '& img': {
      "@media (min-width:360px) and (max-width: 449px)": {
        display:"none"
      } ,
      "@media (min-width:450px) and (max-width:1280px)": {
        display:"none"
      },
    }
  },
  "& .saveButton":{
    width:'26.05vw',
    "@media (min-width:360px) and (max-width: 449px)": {
      width:"75.30vw", 
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width:"75.40vw", 
    },
  },
  "& .LoginText":{
    fontSize:'20px',
    fontWeight:600,
    fontFamily:'Montserrat-Regular',
    "@media (min-width:360px) and (max-width: 449px)": {
      textAlign:"start",
      fontSize:"20px",
      fontWeight:500,
      fontFamily:"Montserrat-semibold"
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      fontFamily:"Montserrat-semibold",
      textAlign:"start",
      fontSize:"20px",
      fontWeight:500,
    },  
  },
  "& .inputName":{
    "@media (min-width:360px) and (max-width: 449px)": {
      fontFamily:"Montserrat-semibold"
    },  
    "@media (min-width:450px) and (max-width:1280px)": {
      fontFamily:"Montserrat-semibold"
    },
  },
  '& .boyImage': {
    '& img': {
      "@media (min-width:450px) and (max-width:1280px)": {
        display:"none"
      },
    }
  },
  "& .contentContainer":{
    "@media (min-width:360) and (max-width: 449px)": {
     alignItems:"flex-start",
     justifyContent:"center"
    },
    "@media (min-width:450px) and (max-width:1280px)": {
    alignItems:"center",
     justifyContent:"center",
     position:"inherit"
    }, 
  }
  })
// Customizable Area End